class AppURL {

  static BaseURL = "https://admin.giftyworld.net/api"
  static VisitorDetails = this.BaseURL + "/getvisitor"
  static PostContact = this.BaseURL+"/postcontact"
  static AllSiteInfo = this.BaseURL+"/allsiteinfo"
  static Allcategory = this.BaseURL+"/allcategory"

  static GetAllProduct     = this.BaseURL+"/getallProduct"
  static GetProduct_Gender = this.BaseURL+"/getallproduct"

  static genderList = this.BaseURL+"/productgender"

  /* static ProductListByCategory = this.BaseURL+"/ProductListByCategory/{category}"
  static ProductListByGender   = this.BaseURL+"/ProductListByGender/{genre}"
  static ProductListByGenderCategory = this.BaseURL+"/ProductListByGenderCategory/{genre}/{category}" */

  static ProductListByGenre(genre){
    return this.BaseURL+"/productgender/"+genre;
  }

  static ProductListByCategory(category){
    return this.BaseURL+"/productlistbycategory/"+category;
  }

  static ProductListByGenderCategory(genre,category){
    return this.BaseURL+"/productlistbygendercategory/"+genre+"/"+category;
  }

}


/* function AppURL() {
    
    const VisitorDetails = () => {
      return fetch("http://127.0.0.1:8000/api/getvisitor", {
        type: "GET",
      }).then((res) => res.json());
    };
  
    return {
        VisitorDetails,
    };
  } 
*/
  
export default AppURL;