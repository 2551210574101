import React, { Component } from 'react'
import AppURL from '../api/AppURL'
import axios from 'axios'
import {Link } from 'react-router-dom'
//import validation from '../../src/validation/validation'
import 'react-toastify/dist/ReactToastify.css'
import { ToastContainer, toast } from 'react-toastify'

export class Contact extends Component  {

    constructor(){
        super();
        this.state = {
            fullname: '',
            address: '',
            email: '',
            phone_number: '',
            subject: '',
            message: '', 
            telephone:''
        }
    }

    componentDidMount(){
        axios.get(AppURL.AllSiteInfo).then(response => {
            //const StatusCode = response.status;
            //if(StatusCode=200){
                const JsonData = (response.data)[0];
                this.setState({
                    address:JsonData['address'],
                    email:JsonData['email'],
                    telephone:JsonData['telephone'],
                    loaderDiv:"d-none",
                    mainDiv:""
                });
            //} 
        }).catch(error=>{

        });
    }
    
    nameOnChange =(event)=>{
        let fullname = event.target.value;
        //alert(fullname);
        this.setState({fullname:fullname})
    }

    emailOnChange =(event)=>{
        let email = event.target.value;
        //alert(email);
        this.setState({email:email})
    }

    numberOnChange =(event)=>{
        let phone_number = event.target.value;
        // alert(phone_number);
        this.setState({phone_number:phone_number})
    }

    subjectOnChange =(event)=>{
        let subject = event.target.value;
        // alert(subject);
        this.setState({subject:subject})
    }

   messageOnChange =(event)=>{
        let message = event.target.value;
        // alert(message);
        this.setState({message:message})
   }

    onFormSubmit = (event) => {
        
        let fullname = this.state.fullname;
        let email = this.state.email;
        let phone_number = this.state.phone_number;
        let subject = this.state.subject;
        let message = this.state.message; 
        let sendBtn = document.getElementById('sendBtn');
        let contactForm = document.getElementById('contactForm');

        if(fullname.length===0) {
            toast.error("Veuillez écrire votre nom, prénom");
        }
        else if(message.length===0) {
            toast.error("Veuillez saisir votre message");
        }
        else if(email.length===0) {
            toast.error("Veuillez écrire votre adresse mail");
        }
        else if(phone_number.length===0) {
            toast.error("Veuillez saisir votre numéro de télephone");
        }
        else if(fullname.length === 0) {
            toast.error("Votre Nom invalide");
        }

        /* else if(!(validation.NameRegx).test(fullname)) {
            toast.error("Votre Nom invalide");
        } */

        else{ 

            sendBtn.innerHTML="Sending...";       
            let MyFormData = new FormData();
            MyFormData.append("fullname",fullname)
            MyFormData.append("email",email)
            MyFormData.append("message",message)
            MyFormData.append("subject",subject)
            MyFormData.append("phone_number",phone_number)

            axios.post(AppURL.PostContact,MyFormData).then(function (response) {
                if(response.status===200 && response.data===1){
                    toast.success("Votre message a été envoyé avec succès");
                    sendBtn.innerHTML="Send";
                    contactForm.reset();
                }
                else{
                    toast.error("error"); 
                    sendBtn.innerHTML="Send";
                }
            })
            .catch(function (error) {
                toast.error(error);
                sendBtn.innerHTML="Send";
            });
        }

        event.preventDefault();

    }

    render () {
        return (
            <section className="contact py-80">
                <div className="container container-lg">
                    <div className="row gy-5">
                        <div className="col-lg-8">
                            <div className="contact-box border border-gray-100 rounded-16 px-24 py-40">

                                <form onSubmit={this.onFormSubmit} id="contactForm">
                                    <h6 className="mb-32">Faire une demande personnalisée</h6>
                                    <div className="row gy-4">
                                        <div className="col-sm-6 col-xs-6">
                                            <label
                                                htmlFor="name"
                                                className="flex-align gap-4 text-sm font-heading-two text-gray-900 fw-semibold mb-4">
                                                Nom & Prénoms{" "}
                                                <span className="text-danger text-xl line-height-1">*</span>{" "}
                                            </label>
                                            <input
                                                type="text"
                                                className="common-input px-16"
                                                id="name"
                                                placeholder="Entrez votre nom"
                                                name="fullname" onChange={this.nameOnChange}
                                            />
                                        </div>
                                        <div className="col-sm-6 col-xs-6">
                                            <label
                                                htmlFor="email"
                                                className="flex-align gap-4 text-sm font-heading-two text-gray-900 fw-semibold mb-4"
                                            >
                                                Adresse Email{" "}
                                                <span className="text-danger text-xl line-height-1">*</span>{" "}
                                            </label>
                                            <input
                                                type="email"
                                                className="common-input px-16"
                                                id="email"
                                                placeholder="Adresse Email"
                                                name="email" onChange={this.emailOnChange} 
                                            />
                                        </div>
                                        <div className="col-sm-6 col-xs-6">
                                            <label
                                                htmlFor="phone"
                                                className="flex-align gap-4 text-sm font-heading-two text-gray-900 fw-semibold mb-4"
                                            >
                                                Télephone
                                                <span className="text-danger text-xl line-height-1">*</span>{" "}
                                            </label>
                                            <input
                                                type="number"
                                                className="common-input px-16"
                                                id="phone"
                                                placeholder="Téléphone*"
                                                name="phone_number"  onChange={this.numberOnChange} 
                                            />
                                        </div>
                                        <div className="col-sm-6 col-xs-6">
                                            <label
                                                htmlFor="subject"
                                                className="flex-align gap-4 text-sm font-heading-two text-gray-900 fw-semibold mb-4"
                                            >
                                                Sujet
                                                <span className="text-danger text-xl line-height-1">
                                                    *
                                                </span>{" "}
                                            </label>
                                            <input
                                                type="text"
                                                className="common-input px-16"
                                                id="subject"
                                                placeholder="Sujet"
                                                name="subject"  onChange={this.subjectOnChange} 
                                            />
                                        </div>
                                        <div className="col-sm-12">
                                            <label
                                                htmlFor="message"
                                                className="flex-align gap-4 text-sm font-heading-two text-gray-900 fw-semibold mb-4"
                                            >
                                                Message
                                                <span className="text-danger text-xl line-height-1">
                                                    *
                                                </span>{" "}
                                            </label>
                                            <textarea
                                                className="common-input px-16"
                                                id="message"
                                                placeholder="Votre message ici"
                                                name="message"  onChange={this.messageOnChange}
                                            />
                                        </div>
                                        <div className="col-sm-12 mt-32">
                                            <button id="sendBtn"
                                                type="submit"
                                                className="btn btn-main py-18 px-32 rounded-8"
                                            >
                                                Soumettre
                                            </button>
                                        </div>
                                    </div>
                                </form>

                            </div>
                        </div>

                        <div className="col-lg-4">
                            <div className="contact-box border border-gray-100 rounded-16 px-24 py-40">
                                <h6 className="mb-48">contactez-nous</h6>
                                <div className="flex-align gap-16 mb-16">
                                    <span className="w-40 h-40 flex-center rounded-circle border border-gray-100 text-main-two-600 text-2xl flex-shrink-0">
                                        <i className="ph-fill ph-phone-call" />
                                    </span>
                                    <Link
                                        to="/tel:+00123456789"
                                        className="text-md text-gray-900 hover-text-main-600"
                                    >
                                        {this.state.telephone}
                                    </Link>
                                </div>
                                <div className="flex-align gap-16 mb-16">
                                    <span className="w-40 h-40 flex-center rounded-circle border border-gray-100 text-main-two-600 text-2xl flex-shrink-0">
                                        <i className="ph-fill ph-envelope" />
                                    </span>
                                    <Link
                                        to="/mailto:support24@marketpro.com"
                                        className="text-md text-gray-900 hover-text-main-600"
                                    >
                                        {this.state.email}
                                    </Link>
                                </div>
                                <div className="flex-align gap-16 mb-0">
                                    <span className="w-40 h-40 flex-center rounded-circle border border-gray-100 text-main-two-600 text-2xl flex-shrink-0">
                                        <i className="ph-fill ph-map-pin" />
                                    </span>
                                    <span className="text-md text-gray-900 ">
                                    {this.state.address}
                                    </span>
                                </div>
                            </div>
                            <div className="mt-24 flex-align flex-wrap gap-16">
                                <Link
                                    to="#"
                                    className="bg-neutral-600 hover-bg-main-600 rounded-8 p-10 px-16 flex-between flex-wrap gap-8 flex-grow-1"
                                >
                                    <span className="text-white fw-medium">Obtenir de l’aide sur appel</span>
                                    <span className="w-36 h-36 bg-main-600 rounded-8 flex-center text-xl text-white">
                                        <i className="ph ph-headset" />
                                    </span>
                                </Link>
                                <Link
                                    to="#"
                                    className="bg-neutral-600 hover-bg-main-600 rounded-8 p-10 px-16 flex-between flex-wrap gap-8 flex-grow-1"
                                >
                                    <span className="text-white fw-medium">obtenir une direction</span>
                                    <span className="w-36 h-36 bg-main-600 rounded-8 flex-center text-xl text-white">
                                        <i className="ph ph-map-pin" />
                                    </span>
                                </Link>
                            </div>
                        </div>

                    </div>
                </div>

                <ToastContainer />

            </section>
        )
    }
}

export default Contact