import { Link } from 'react-router-dom'
/* import Apple from '../../assets/images/apple.png'
import Google from '../../assets/images/google.png' */
import AppURL from '../api/AppURL';
import axios from 'axios'
//import ReactHtmlParser from 'react-html-parser';
import React, { Component } from 'react'

//const FooterTwo = () => {

export class FooterTwo extends Component{

    constructor(){
        super();
        this.state = {
            address:"",
            email:"",
            facebook_link:"",
            twitter_link:"",
            instagram_link:"",
            youtube_link:"",
            copyright_text:"", 
            loaderDiv:"",
            mainDiv:"d-none",
            about: ''
        }
    }

    componentDidMount(){
        axios.get(AppURL.AllSiteInfo).then(response => {
            //let StatusCode = response.status;
            //if(StatusCode===200){
                let JsonData = (response.data)[0];
                this.setState({
                    address:JsonData['address'],
                    email:JsonData['email'],
                    about:JsonData['about'],
                    copyright_text:JsonData['copyright_text'],
                    telephone:JsonData['telephone'],
                    facebook_link:JsonData['facebook_link'],
                    twitter_link:JsonData['twitter_link'],
                    instagram_link:JsonData['instagram_link'],
                    youtube_link:JsonData['youtube_link'],
                    loaderDiv:"d-none",
                    mainDiv:""
                });
            //} 

        }).catch(error=>{

        });

    }

    render() {
        return (
            <footer className="footer py-80">
                <div className="container container-lg">
                    <div className="footer-item-two-wrapper d-flex align-items-start flex-wrap">
                        <div className="footer-item max-w-275">
                            <div className="footer-item__logo">
                                <Link to="/">
                                    {" "}
                                    <img src="assets/images/logo/logo-two-black.png" alt="" />
                                </Link>
                            </div>
                            <p className="mb-24">
                                {this.state.about}
                            </p>
                            <div className="flex-align gap-16 mb-16">
                                <span className="w-32 h-32 flex-center rounded-circle border border-gray-100 text-main-two-600 text-md flex-shrink-0">
                                    <i className="ph-fill ph-phone-call" />
                                </span>
                                <Link
                                    to="/tel:+00123456789"
                                    className="text-md text-gray-900 hover-text-main-600"
                                >
                                    {this.state.telephone}
                                </Link>
                            </div>

                            <div className="flex-align gap-16 mb-16">
                                <span className="w-32 h-32 flex-center rounded-circle border border-gray-100 text-main-two-600 text-md flex-shrink-0">
                                    <i className="ph-fill ph-envelope" />
                                </span>
                                <Link
                                    to="/mailto:support24@marketpro.com"
                                    className="text-md text-gray-900 hover-text-main-600"
                                >
                                    {this.state.email}
                                </Link>
                            </div>

                            <div className="flex-align gap-16 mb-16">
                                <span className="w-32 h-32 flex-center rounded-circle border border-gray-100 text-main-two-600 text-md flex-shrink-0">
                                    <i className="ph-fill ph-map-pin" />
                                </span>
                                <span className="text-md text-gray-900 ">
                                {this.state.address}
                                </span>
                            </div>

                        </div>

                        <div className="footer-item">
                            <h6 className="footer-item__title">À propos de nous</h6>
                            <ul className="footer-menu">
                                <li className="mb-16">
                                    <Link to="/shop" className="text-gray-600 hover-text-main-600">
                                        Company Profile
                                    </Link>
                                </li>
                                <li className="mb-16">
                                    <Link to="/shop" className="text-gray-600 hover-text-main-600">
                                        All Retail Store
                                    </Link>
                                </li>
                                
                                <li className="mb-16">
                                    <Link to="/shop" className="text-gray-600 hover-text-main-600">
                                        Contact
                                    </Link>
                                </li>
                                
                            </ul>
                        </div>

                        <div className="footer-item">
                            <h6 className="footer-item__title">Assistance</h6>
                            <ul className="footer-menu">
                                <li className="mb-16">
                                    <Link to="/shop" className="text-gray-600 hover-text-main-600">
                                        Center d'aide
                                    </Link>
                                </li>
                                <li className="mb-16">
                                    <Link
                                        to="/contact"
                                        className="text-gray-600 hover-text-main-600"
                                    >
                                        Contact
                                    </Link>
                                </li>
                                {/* <li className="mb-16">
                                    <Link to="/shop" className="text-gray-600 hover-text-main-600">
                                        Gift Card
                                    </Link>
                                </li>
                                <li className="mb-16">
                                    <Link to="/shop" className="text-gray-600 hover-text-main-600">
                                        Report Abuse
                                    </Link>
                                </li> */}
                                {/* <li className="mb-16">
                                    <Link to="/shop" className="text-gray-600 hover-text-main-600">
                                        Submit and Dispute
                                    </Link>
                                </li> */}
                                {/* <li className="mb-16">
                                    <Link to="/shop" className="text-gray-600 hover-text-main-600">
                                        Policies &amp; Rules
                                    </Link>
                                </li>
                                <li className="mb-16">
                                    <Link to="/shop" className="text-gray-600 hover-text-main-600">
                                        Online Shopping
                                    </Link>
                                </li>
                                <li className="">
                                    <Link to="/shop" className="text-gray-600 hover-text-main-600">
                                        Redeem Voucher
                                    </Link>
                                </li> */}
                            </ul>
                        </div>

                        <div className="footer-item">
                            <h6 className="footer-item__title">Compte</h6>
                            <ul className="footer-menu">
                                <li className="mb-16">
                                    <Link to="/shop" className="text-gray-600 hover-text-main-600">
                                        Compte
                                    </Link>
                                </li>
                                <li className="mb-16">
                                    <Link to="/shop" className="text-gray-600 hover-text-main-600">
                                        Historique
                                    </Link>
                                </li>
                                <li className="mb-16">
                                    <Link to="/shop" className="text-gray-600 hover-text-main-600">
                                        Panier
                                    </Link>
                                </li>
                                {/* <li className="mb-16">
                                    <Link to="/shop" className="text-gray-600 hover-text-main-600">
                                        Compare
                                    </Link>
                                </li> */}
                                {/* <li className="mb-16">
                                    <Link to="/shop" className="text-gray-600 hover-text-main-600">
                                        Help Ticket
                                    </Link>
                                </li> */}
                                {/* <li className="mb-16">
                                    <Link to="/wishlist" className="text-gray-600 hover-text-main-600">
                                        Wishlist
                                    </Link>
                                </li> */}
                                
                            </ul>
                        </div>
                        <div className="footer-item">
                            <h6 className="footer-item__title">Information</h6>
                            <ul className="footer-menu">
                                <li className="mb-16">
                                    <Link to="/shop" className="text-gray-600 hover-text-main-600">
                                        Devenir un vendeur
                                    </Link>
                                </li>
                              
                                <li className="mb-16">
                                    <Link to="/shop" className="text-gray-600 hover-text-main-600">
                                        Nos fournisseurs
                                    </Link>
                                </li>
                               {/*  <li className="mb-16">
                                    <Link to="/shop" className="text-gray-600 hover-text-main-600">
                                        Extended Plan
                                    </Link>
                                </li>
                                <li className="mb-16">
                                    <Link to="/shop" className="text-gray-600 hover-text-main-600">
                                        Extended Plan
                                    </Link>
                                </li> */}
                                {/* <li className="mb-16">
                                    <Link to="/shop" className="text-gray-600 hover-text-main-600">
                                        Community
                                    </Link>
                                </li>
                                <li className="mb-16">
                                    <Link to="/shop" className="text-gray-600 hover-text-main-600">
                                        Community
                                    </Link>
                                </li> */}
                            </ul>
                        </div>
                        <div className="footer-item">
                            <h6 className="">Achèter</h6>
                            <p className="mb-16">Gifty App est disponible.<br></br> Obtenez-le maintenant
                            </p>
                            <div className="flex-align gap-8 my-32">
                                <Link to="/https://www.apple.com/store" className="">
                                    <img src="assets/images/thumbs/store-img1.png" alt="" />
                                </Link>
                                <Link to="/https://play.google.com/store/apps?hl=en" className="">
                                    <img src="assets/images/thumbs/store-img2.png" alt="" />
                                </Link>
                            </div>

                            <ul className="flex-align gap-16">
                                <li>
                                    <Link
                                        to={this.state.facebook_link}
                                        className="w-44 h-44 flex-center bg-main-two-50 text-main-two-600 text-xl rounded-8 hover-bg-main-two-600 hover-text-white"
                                    >
                                        <i className="ph-fill ph-facebook-logo" />
                                    </Link>
                                </li>
                                <li>
                                    <Link
                                        to={this.state.twitter_link}
                                        className="w-44 h-44 flex-center bg-main-two-50 text-main-two-600 text-xl rounded-8 hover-bg-main-two-600 hover-text-white"
                                    >
                                        <i className="ph-fill ph-twitter-logo" />
                                    </Link>
                                </li>
                                <li>
                                    <Link
                                        to={this.state.instagram_link}
                                        className="w-44 h-44 flex-center bg-main-two-50 text-main-two-600 text-xl rounded-8 hover-bg-main-two-600 hover-text-white"
                                    >
                                        <i className="ph-fill ph-instagram-logo" />
                                    </Link>
                                </li>
                                <li>
                                    <Link
                                        to={this.state.youtube_link}
                                        className="w-44 h-44 flex-center bg-main-two-50 text-main-two-600 text-xl rounded-8 hover-bg-main-two-600 hover-text-white"
                                    >
                                        <i className="ph-fill ph-youtube-logo" />
                                    </Link>
                                </li>
                            </ul>

                        </div>
                    </div>
                </div>
            </footer>

        )
    }
}

export default FooterTwo